
import { defineComponent } from "vue";
import Button from "primevue/button";
import InputSwitch from "primevue/inputswitch";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import TextArea from "primevue/textarea";
import SplitButton from "primevue/splitbutton";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf, between } from "@vuelidate/validators";

import Search from "@/components/Search.vue";
import AttachmentRecords from "@/components/Attachments/AttachmentRecords.vue";
import CollapsibleSection from "@/components/UI/CollapsibleSection.vue";

import TicketService from "@/services/TicketService";
import BillingService from "@/services/BillingService";
import CustomerService from "@/services/CustomerService";
import Bill from "@/types/bill";
import Ticket from "@/types/ticket";
import BillingTypeItem from "@/types/billingTypeItem";
import CustTypeItem from "@/types/custtypeitem";
import Part from "@/types/part";
import { mapActions, mapGetters, mapState } from "vuex";
import Response from "@/types/response/response";
import CustResponse from "@/types/response/custResponse";
import Attachment from "@/types/attachment";
import Utils from "@/utility/utils";
import Customer from "@/types/customer";

export default defineComponent({
  name: "Quick Ticket",
  components: {
    Search,
    Calendar,
    InputSwitch,
    Button,
    Dropdown,
    InputText,
    TextArea,
    SplitButton,
    AttachmentRecords,
    CollapsibleSection,
  },
  emits: [
    "hide",
    "onSave",
    "onStatusUpdate",
    "onTicketBilling",
    "onFetchAttachments",
    "onSaveAttachment",
  ],
  props: {
    show: Boolean,
    isEditingTicket: {
      type: Boolean,
      default: false,
    },
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    active: [Number, Function],
    // ticketRecords: {
    //   type: Array,
    // },
    ticket: Object,
    bill: Object,
    first: Number,
    rows: Number,
    customer: String, // to cature records for a specfic customer
  },
  created() {
    this.isHidden = this.show;
    this.initFields();

    if (this.getParts?.length === 0) {
      this.fetchParts({
        fieldnames: "part_no category wrap_desc",
      });
    }
    // loads default customer if customer prop is valid
    if (this.customer) {
      this.newTicket.cust = this.customer;
      this.newTicketCust = this.ticket?.cust_name || "";
      this.custService.getCustomer(this.newTicket.cust, this.getClient, "").then((response) => {
        if (response) {
          const cust = response as any;
          this.newTicketCust = cust.name;
          this.setValidParts(cust.type);
        }
      });
    }
    if (this.ticket != null) {
      this.newTicket = JSON.parse(JSON.stringify(this.ticket as Ticket));
      this.part = (this.ticket as Ticket).part === "" ? null : (this.ticket as Ticket).part;
      if (this.newTicket.desc) {
        this.newTicket.desc = this.newTicket.desc?.replaceAll('^', '\n');
      }

      // ADD SEPERATE STATE VALUES FOR CUSTOMER TICKET
      if (
        this.getCurrentIndex == -1 &&
        (this.getTickets as Array<Ticket>)[
          this.active as number
        ]?.id == this.newTicket?.id
      ) {
        this.selectedTicket = JSON.parse(
          JSON.stringify( this.newTicket as Ticket)
        );
        this.storeTicketsSnapshot = JSON.parse(
          JSON.stringify(this.getTickets as unknown as Array<Ticket>)
        );

        (this.ticket as Ticket).closed == "" ||
        (this.ticket as Ticket).closed == "N"
          ? (this.saveOptions[1].label = "Close")
          : (this.saveOptions[1].label = "Open");
        this.updateCurrentIndex(this.isValueNull(this.active));
        this.initCust();
        this.setBillable(this.newTicket.type);
      }
    } else {
      this.initFields();
    }
  },
  mounted() {
    this.initControls();
  },
  computed: {
    ...mapState(["control"]),
    ...mapGetters({
      getBillingTypes: "billing/getBillingTypeItems",
      getUsers: "users/getUsers",
      getParts: "billing/getParts",
      getCustTypes: "billing/getCustTypeItems",
      getUser: "session/getUser",
      getCurrentIndex: "billing/getCurrentIndex",
      getTickets: "billing/getTickets",
      getClient: "session/getClient",
    }),
    ticketFieldsHaveChanged() {
      return Object.keys(this.newTicket as Ticket).some((field: string) => {
        const newTicketField = this.newTicket[field as keyof Ticket];
        const oldTicketField = this.selectedTicket[field as keyof Ticket];
        if (
          field === "attachments_items" &&
          newTicketField != null
        ) {
          return (
            newTicketField.toString() !== oldTicketField?.toString()
          );
        } else if(field === "date" &&
          newTicketField != null
        ) {
          const newDate = Utils.formatDate(newTicketField as string);
          const oldDate = Utils.formatDate(oldTicketField as string);
          return newDate != oldDate;
        } else {
          return (
            newTicketField != oldTicketField
          );
        }
      });
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      newTicket: {
        type: { required },
      },
      newBill: {
        hours: {
          required: requiredIf(() => {
            if (this.isEditingTicket && !this.ticketFieldsHaveChanged)
              return true;
            return (
              this.newBill?.desc?.trim().length > 0 ||
              this.newBill?.notes?.trim().length > 0
            );
          }),
          between: between(0, 24),
        },
        date: {
          requiredIf: requiredIf(() => {
            return (
              this.newBill?.desc?.trim().length > 0 ||
              this.newBill?.notes?.trim().length > 0 ||
              !!this.newBill?.hours?.toString()
            );
          }),
        },
      },
    };
  },
  data() {
    return {
      sectionsStatus: {
        ticketEntry: true,
        attachments: true,
        addTime: true,
      },
      isTicketEntryIconDown: true,
      isHidden: true,
      ticketAttachments: [] as Array<Attachment>,

      showQuickTicketDialog: false,
      editTicketField: false,
      newTicket: {} as Ticket,
      selectedTicket: {} as Ticket,
      newBill: {} as Bill,
      billable: false,
      newTicketCust: "",
      submitted: false,
      isLoadingTicket: false,
      storeTicketsSnapshot: [] as Array<Ticket>,
      parts: [] as Array<Part>,
      part: "" as string | null,
      saveOptions: [
        {
          label: "Save & Close Ticket",
          command: () => {
            this.saveAndClose();
          },
        },
        {
          label: "",
          command: () => {
            this.updateTicketStatus();
          },
        },
      ],
      ticketService: new TicketService(process.env.VUE_APP_ABSTRACTION_API),
      billService: new BillingService(process.env.VUE_APP_ABSTRACTION_API),
      custService: new CustomerService(),
    };
  },
  methods: {
    ...mapActions({
      addNotification: "notification/add",
      fetchTickets: "billing/getTickets",
      updateTicket: "billing/updateTicket",
      updateTicketRecord: "billing/updateTicketRecord",
      updateCurrentIndex: "billing/updateCurrentIndex",
      addTicketRecord: "billing/addTicketRecord",
      fetchAttachments: "attachments/fetchAttachments",
      fetchControl: "billing/getControls",
      fetchParts: "billing/fetchParts",
    }),

    getSectionIconClass(status: {[key:string]: boolean}, section: "ticketEntry" | "attachments" | "addTime") {
      return status[section]
        ? "pi pi-chevron-down ml-2"
        : "pi pi-chevron-right ml-2";
    },

    toggleSectionIcon(tab: "ticketEntry" | "attachments" | "addTime") {
      this.sectionsStatus[tab] = !this.sectionsStatus[tab];
    },
    handleDeleted(event: any) {
      event.success ? this.$emit("onSave", { ticket: this.newTicket, success: true }) : this.$emit("onSave", { error: event.error, success: false });
    },
    handleSaved(event: any){
      event.success ? this.$emit("onSave", { success: true }) : this.$emit("onSave", { success: false, error: event.error });
    },
    initControls() {
      const CONTROLS = [
        {
          id: "BILL",
          filename: "CONTROL",
          control: "BILL.CONTROL",
          procedure: "BILL.CONTROL",
          getter: this.getBillingTypes,
        },
      ];
      CONTROLS.forEach((element) => {
        if (!element.getter || element.getter.length === 0) {
          this.fetchControl(element)
        }
      });
    },
    isValueNull(value: unknown) {
      if (typeof value === "number") {
        return value;
      } else if (typeof value === "function") {
        return value();
      } else {
        return value;
      }
    },
    initCust() {
      if (this.newTicket.cust) {
        this.newTicketCust = this.newTicket.cust_name || "";
        this.custService
          .getCustomer(this.newTicket.cust, this.getClient, "")
          .then((response) => {
            if ((response as CustResponse).cust_items?.length > 0) {
              const cust = (response as CustResponse).cust_items[0];
              this.newTicketCust = cust.name;
              this.setValidParts(cust.type);
            }
            else if ((response as Customer).cust_id !== undefined) {
              const cust = response as Customer;
              this.newTicketCust = cust.name ?? "";
              this.setValidParts('');
            }
          });
      }      
    },
    confirmTime() {
      return new Promise((resolve) => {
        this.$confirm.require({
          message:
            "You have entered more than 12 hours, do you want to proceed?",
          header: "Confirmation",
          icon: "pi pi-exclamation-triangle",
          accept: () => {
            resolve(true);
          },
          reject: () => {
            resolve(false);
          },
        });
      });
    },
    sortArray(x: Part, y: Part) {
      if (x.part_no < y.part_no) {
        return -1;
      }
      if (x.part_no > y.part_no) {
        return 1;
      }
      return 0;
    },
    setValidParts(custType: string) {
      if (custType === undefined || custType === null) {
        this.parts = this.getParts;
        return;
      }
      let custItems = [];
      custItems = this.getCustTypes.filter((custitem: CustTypeItem) => {
        return custitem.cust_type === custType;
      });
      if (custItems !== null && custItems !== undefined && custItems !== []) {
        let partCats = [] as string[];
        custItems.forEach((item: CustTypeItem) => {
          let partCatItems = item.parts_cat_items;
          partCatItems.forEach((partObj) => {
            if (
              partObj.parts_cat !== undefined &&
              !partCats.includes(partObj.parts_cat)
            ) {
              partCats.push(partObj.parts_cat);
            }
          });
        });
        if (partCats.length !== 0) {
          this.parts = [];
          partCats.forEach((category: string) => {
            this.parts.push(
              ...this.getParts?.filter((part: Part) => {
                return part.category === category;
              })
            );
          });
        } else {
          this.parts = this.getParts;
        }
      } else {
        this.parts = this.getParts;
      }
    },
    setPart() {
      this.newTicket.part = this.part ?? "";
    },
    async saveQuickTicket() {
      this.submitted = true;

      const isBillFieldsCorrect = await this.v$.newBill.$validate();
      const isTicketFieldsCorrect = await this.v$.newTicket.$validate();
      if (
        this.isEditingTicket &&
        isBillFieldsCorrect &&
        isTicketFieldsCorrect
      ) {
        // FORM IN THE ACCORDION
        // check whether bill info has been entered - whether to make a billing API call
        this.newTicket.date = Utils.formatDate(this.newTicket.date);

        this.isLoadingTicket = true;

        const oldTicket = this.storeTicketsSnapshot.find((ticket: Ticket) => ticket.id === this.newTicket.id);

        if (
          this.isEditingTicket &&
          this.ticketFieldsHaveChanged &&
          this.newTicket.closed !== (oldTicket as Ticket)?.closed &&
          this.newBill.hours != null
        ) {
          this.newBill.date = Utils.formatDate(this.newBill.date);
          this.newBill.hours = this.newBill.hours?.toString();

          if (parseInt(this.newBill.hours) > 12) {
            const choice = await this.confirmTime();
            if (!choice) return;
          }

          this.billService
            .postBillingInfo({
              ...this.newBill,
              ticket: this.newTicket.id,
              cust_number: this.newTicket.cust,
            })
            .then(() => {
              this.$emit("onTicketBilling", {
                success: true,
                ticketId: this.newTicket.id,
              });

              this.addNotification({
                message: `Successfully Billed Time against Ticket Id ${this.newTicket.id}`,
                type: "success",
              });

              this.ticketService
                .updateTicket({
                  newTicket: this.newTicket,
                  oldTicket: oldTicket,
                  ticket_id: this.newTicket.id,
                })
                .then(() => {
                  this.isLoadingTicket = false;
                  this.addNotification({
                    message: `Successfully updated against ticket Id ${this.newTicket.id}`,
                    type: "success",
                  });

                  this.updateTicketRecord(
                    JSON.parse(JSON.stringify(this.newTicket))
                  );
                  this.selectedTicket = JSON.parse(
                    JSON.stringify(this.newTicket as Ticket)
                  );

                  this.$emit("onSave", {
                    ticket: this.newTicket,
                    success: true,
                  });
                })
                .catch(() => {
                  this.isLoadingTicket = false;
                  this.$emit("onSave", {
                    ticket: this.newTicket,
                    success: true,
                  });
                });
            })
            .catch(() => {
              this.isLoadingTicket = false;
              this.addNotification({
                message: `Could not bill against ticket Id ${this.newTicket.id}. Please search for this ticket and do this manually`,
                type: "error",
              });
              this.$emit("onTicketBilling", { success: false });
            });
        } else if (this.isEditingTicket && this.ticketFieldsHaveChanged) {
          // ticket has been modified
          const oldTicket = this.storeTicketsSnapshot.find((ticket: Ticket) => ticket.id === this.newTicket.id);

          this.ticketService
            .updateTicket({
              newTicket: this.newTicket,
              oldTicket: oldTicket,
              ticket_id: this.newTicket.id,
            })
            .then(async () => {
              let successMessage = `Successfully Updated Ticket Id ${this.newTicket.id}.`;

              this.updateTicketRecord(
                JSON.parse(JSON.stringify(this.newTicket))
              );
              this.selectedTicket = JSON.parse(
                JSON.stringify(this.newTicket as Ticket)
              );

              // this.$emit("onSave", { ticket: this.newTicket, success: true });

              // check if bill field has been modified
              if (
                this.newBill?.hours != null ||
                (this.newBill?.desc != null && this.newBill?.desc != "") ||
                (this.newBill?.notes != null && this.newBill?.notes != "")
              ) {
                this.newBill.date = Utils.formatDate(this.newBill.date);
                this.newBill.hours = this.newBill.hours?.toString();

                successMessage = `Ticket Updated And Time Entered Successfully, Ticket Id: ${this.newTicket.id}`;
                if (parseInt(this.newBill.hours) > 12) {
                  const choice = await this.confirmTime();
                  if (!choice) return;
                }

                this.billService
                  .postBillingInfo({
                    ...this.newBill,
                    ticket: this.newTicket.id,
                    cust_number: this.newTicket.cust,
                  })
                  .then(() => {
                    this.addNotification({
                      message: successMessage,
                      type: "success",
                    });

                    this.isLoadingTicket = false;
                    this.submitted = false;
                    this.resetBill();

                    this.$emit("onTicketBilling", {
                      success: true,
                      ticketId: this.newTicket.id,
                    });
                  })
                  .catch(() => {
                    this.addNotification({
                      message: `Could not bill against ticket Id ${this.newTicket.id}. Please search for this ticket and do this manually`,
                      type: "error",
                    });
                    this.isLoadingTicket = false;
                    this.$emit("onTicketBilling", { success: false });
                  });
              } else {
                this.addNotification({
                  message: successMessage,
                  type: "success",
                });
              }
              this.isLoadingTicket = false;
              this.submitted = false;
            })
            .catch(() => {
              this.isLoadingTicket = false;
              this.$emit("onSave", { ticket: this.newTicket, success: false });
            });
        } else if (this.isEditingTicket && this.newBill.hours != null) {
          // bill time against ticket only
          this.newBill.date = Utils.formatDate(this.newBill.date);
          this.newBill.hours = this.newBill.hours?.toString();

          if (parseInt(this.newBill.hours) > 12) {
            const choice = await this.confirmTime();
            if (!choice) return;
          }

          this.billService
            .postBillingInfo({
              ...this.newBill,
              ticket: this.newTicket.id,
              cust_number: this.newTicket.cust,
            })
            .then(() => {
              this.addNotification({
                message: `Successfully Billed Time against Ticket Id ${this.newTicket.id}`,
                type: "success",
              });

              this.isLoadingTicket = false;
              this.submitted = false;
              this.resetBill();
              this.$emit("onTicketBilling", {
                success: true,
                ticketId: this.newTicket.id,
              });
            })
            .catch(() => {
              this.addNotification({
                message: `Could not bill against ticket Id ${this.newTicket.id}. Please search for this ticket and do this manually`,
                type: "error",
              });
              this.isLoadingTicket = false;
              this.$emit("onTicketBilling", { success: false });
            });
        }
        return;
      } else {
        // NEW TICKET
        if (isTicketFieldsCorrect && isBillFieldsCorrect) {
          this.newTicket.date = Utils.formatDate(this.newTicket.date);
          this.newBill.date = Utils.formatDate(this.newBill.date);
          this.newBill.hours = this.newBill.hours?.toString();

          if (parseInt(this.newBill.hours) > 12) {
            const choice = await this.confirmTime();
            if (!choice) return;
          }

          // TICKET HAS BEEN BILLED AGAINST
          if (!!this.newBill?.date && this.newBill.hours != null) {
            this.isLoadingTicket = true;

            this.ticketService
              .newTicket(this.newTicket)
              .then((response) => {
                this.newTicket.id = (response as any).recordId;

                this.$emit("onSave", { ticket: this.newTicket, success: true });

                this.billService
                  .postBillingInfo({
                    ...this.newBill,
                    ticket: this.newTicket.id,
                    cust_number: this.newTicket.cust,
                  })
                  .then(() => {
                    this.addNotification({
                      message: `Ticket Created And Time Entered Successfully, Ticket Id: ${this.newTicket.id}`,
                      type: "success",
                    });

                    this.isLoadingTicket = false;
                    this.newTicketCust = "";
                    this.billable = false;
                    this.submitted = false;
                    this.$emit("onTicketBilling", {
                      success: true,
                      ticketId: this.newTicket.id,
                    });
                    this.addTicketRecord(this.newTicket);
                  })
                  .catch(() => {
                    this.addNotification({
                      message: `Could not bill against ticket Id ${this.newTicket.id}. Please search for this ticket and do this manually`,
                      type: "error",
                    });
                    this.isLoadingTicket = false;
                    this.$emit("onTicketBilling", {
                      success: true,
                      ticketId: this.newTicket.id,
                    });
                    this.addTicketRecord(this.newTicket);
                  });
              })
              .catch(() => {
                this.$emit("onSave", {
                  ticket: this.newTicket,
                  success: false,
                });
                this.isLoadingTicket = false;
              });
          } else {
            // NO BILLING INFO HAS BEEN ADDED
            this.isLoadingTicket = true;

            this.ticketService
              .newTicket(this.newTicket)
              .then((response) => {
                this.addNotification({
                  message: `Successfully created ticket Id ${
                    (response as any).recordId
                  }.`,
                  type: "success",
                });

                this.isLoadingTicket = false;
                this.newTicketCust = "";
                this.billable = false;
                this.submitted = false;
                this.newTicket.id = (response as any).recordId;
                this.$emit("onSave", { ticket: this.newTicket, success: true });
              })
              .catch(() => {
                this.isLoadingTicket = false;
                this.$emit("onSave", {
                  ticket: this.newTicket,
                  success: false,
                });
              });
          }
        } else {
          return;
        }
      }
    },
    async saveAndClose() {
      this.submitted = true;

      if (this.newTicket.closed === "Y") return;

      const isTicketFieldsCorrect = await this.v$.newTicket.$validate();
      const isBillFieldsCorrect = await this.v$.newBill.$validate();

      if (
        this.isEditingTicket &&
        isTicketFieldsCorrect &&
        isBillFieldsCorrect
      ) {
        this.newTicket.date = Utils.formatDate(this.newTicket.date);
        this.isLoadingTicket = true;

        if (this.isEditingTicket && this.ticketFieldsHaveChanged) {
          const oldTicket = this.storeTicketsSnapshot.find((ticket: Ticket) => ticket.id === this.newTicket.id);

          this.ticketService
            .updateTicket({
              newTicket: this.newTicket,
              oldTicket: oldTicket,
              ticket_id: this.newTicket.id,
            })
            .then(async () => {
              this.updateTicketRecord(
                JSON.parse(JSON.stringify(this.newTicket))
              );
              this.selectedTicket = JSON.parse(
                JSON.stringify(this.newTicket as Ticket)
              );
              this.$emit("onSave", { ticket: this.newTicket, success: true });

              // check if bill field has been modified
              if (
                this.newBill?.hours != null ||
                (this.newBill?.desc != null && this.newBill?.desc != "") ||
                (this.newBill?.notes != null && this.newBill?.notes != "")
              ) {
                this.newBill.date = Utils.formatDate(this.newBill.date);
                this.newBill.hours = this.newBill.hours?.toString();

                if (parseInt(this.newBill.hours) > 12) {
                  const choice = await this.confirmTime();
                  if (!choice) return;
                }

                this.billService
                  .postBillingInfo({
                    ...this.newBill,
                    ticket: this.newTicket.id,
                    cust_number: this.newTicket.cust,
                  })
                  .then(() => {

                    this.isLoadingTicket = false;
                    this.submitted = false;
                    this.resetBill();

                    this.$emit("onTicketBilling", {
                      success: true,
                      ticketId: this.newTicket.id,
                    });
                    this.postClosedTicket();
                  })
                  .catch(() => {
                    this.addNotification({
                      message: `Could not bill against ticket Id ${this.newTicket.id}. Please search for this ticket and do this manually`,
                      type: "error",
                    });
                    this.isLoadingTicket = false;
                    this.$emit("onTicketBilling", { success: false });
                  });
              } else {
                this.postClosedTicket();
              }
              this.isLoadingTicket = false;
              this.submitted = false;
            })
            .catch(() => {
              this.isLoadingTicket = false;
              this.$emit("onSave", { ticket: this.newTicket, success: false });
            });
        } else if (this.isEditingTicket && this.newBill.hours != null) {
          this.newBill.date = Utils.formatDate(this.newBill.date);
          this.newBill.hours = this.newBill.hours?.toString();

          if (parseInt(this.newBill.hours) > 12) {
            const choice = await this.confirmTime();
            if (!choice) return;
          }

          this.billService
            .postBillingInfo({
              ...this.newBill,
              ticket: this.newTicket.id,
              cust_number: this.newTicket.cust,
            })
            .then(() => {

              this.isLoadingTicket = false;
              this.submitted = false;
              this.resetBill();

              this.postClosedTicket();
              this.$emit("onTicketBilling", {
                success: true,
                ticketId: this.newTicket.id,
              });
            })
            .catch(() => {
              this.addNotification({
                message: `Could not bill against ticket Id ${this.newTicket.id}. Please search for this ticket and do this manually`,
                type: "error",
              });
              this.isLoadingTicket = false;
              this.$emit("onTicketBilling", { success: false });
            });
        }
        return;
      } else {
        // NEW TICKET
        if (isTicketFieldsCorrect && isBillFieldsCorrect) {
          this.newTicket.date = Utils.formatDate(this.newTicket.date);
          this.newBill.date = Utils.formatDate(this.newBill.date);
          this.newBill.hours = this.newBill.hours?.toString();

          if (parseInt(this.newBill.hours) > 12) {
            const choice = await this.confirmTime();
            if (!choice) return;
          }
          // CREATE NEW TICKET
          this.isLoadingTicket = true;


          this.ticketService
            .newTicket(this.newTicket)
            .then((response) => {
              this.newTicket.id = (response as any).recordId;
              this.$emit("onSave", { ticket: this.newTicket, success: true });

              // BILL AND CLOSE TICKET
              if (!!this.newBill?.date && this.newBill.hours != null) {
                this.billService
                  .postBillingInfo({
                    ...this.newBill,
                    ticket: this.newTicket.id,
                    cust_number: this.newTicket.cust,
                  })
                  .then(() => {
                    this.newTicket.closed = "";
                    const closedNewTicket = JSON.parse(
                      JSON.stringify(this.newTicket)
                    );
                    closedNewTicket.closed = "Y";

                    this.$emit("onTicketBilling", {
                      success: true,
                      ticketId: this.newTicket.id,
                    });

                    this.ticketService
                      .updateTicket({
                        newTicket: closedNewTicket,
                        oldTicket: this.newTicket,
                        ticket_id: this.newTicket.id,
                      })
                      .then(() => {
                        this.addNotification({
                          message: `Successfully created and closed ticket Id ${this.newTicket.id}`,
                          type: "success",
                        });
                        this.isLoadingTicket = false;
                        this.newTicketCust = "";
                        this.billable = false;
                        this.submitted = false;
                        this.$emit("onStatusUpdate", { success: true });
                      })
                      .catch((error) => {
                        this.addNotification({
                          message: `Could not close ticket Id ${this.newTicket.id}.`,
                          type: "error",
                        });
                        this.isLoadingTicket = false;
                        this.$emit("onStatusUpdate", { success: false });
                      });
                  })
                  .catch(() => {
                    this.addNotification({
                      message: `Could not bill against ticket Id ${this.newTicket.id}. Please search for this ticket and do this manually`,
                      type: "error",
                    });
                    this.isLoadingTicket = false;
                    this.$emit("onTicketBilling", {
                      ticket: this.newTicket,
                      success: false,
                    });
                  });
              } else {
                // CLOSE TICKET
                this.newTicket.closed = "";
                const closedNewTicket = JSON.parse(
                  JSON.stringify(this.newTicket)
                );
                closedNewTicket.closed = "Y";
                this.ticketService
                  .updateTicket({
                    newTicket: closedNewTicket,
                    oldTicket: this.newTicket,
                    ticket_id: this.newTicket.id,
                  })
                  .then(() => {
                    this.addNotification({
                      message: `Successfully created and closed ticket Id ${this.newTicket.id}`,
                      type: "success",
                    });
                    this.isLoadingTicket = false;
                    this.newTicketCust = "";
                    this.billable = false;
                    this.submitted = false;
                    this.$emit("onStatusUpdate", { success: true });
                  })
                  .catch((error) => {
                    this.addNotification({
                      message: `Could not close ticket Id ${this.newTicket.id}.`,
                      type: "error",
                    });
                    this.isLoadingTicket = false;
                    this.$emit("onStatusUpdate", { success: false });
                  });
              }
            })
            .catch(() => {
              this.$emit("onSave", { ticket: this.newTicket, success: false });
              this.isLoadingTicket = false;
            });
        } else {
          return;
        }
      }
    },
    updateTicketStatus() {
      const oldTicket = this.storeTicketsSnapshot.find((ticket: Ticket) => ticket.id === this.newTicket.id);

      this.isLoadingTicket = true;

      if (
        this.newTicket.closed == "Y" &&
        this.saveOptions[1].label == "Close"
      ) {
        this.newTicket.closed = "Y";
      } else if (
        (this.newTicket.closed == "N" || this.newTicket.closed == "") &&
        this.saveOptions[1].label == "Open"
      ) {
        this.newTicket.closed = "N";
      } else if (this.newTicket.closed == "N" || this.newTicket.closed == "") {
        this.newTicket.closed = "Y";
      } else {
        this.newTicket.closed = "N";
      }

      const ticketStatus =
        this.newTicket.closed == "N" || this.newTicket.closed == "";

      this.ticketService
        .updateTicket({
          newTicket: this.newTicket,
          oldTicket: oldTicket,
          ticket_id: this.newTicket.id,
        })
        .then(() => {
          this.isLoadingTicket = false;

          this.resetBill();
          this.addNotification({
            message: `Successfully ${
              ticketStatus ? "opened" : "closed"
            } ticket Id ${this.newTicket.id}`,
            type: "success",
          });
          this.$emit("onStatusUpdate", {
            ticket: this.newTicket,
            success: true,
          });
        })
        .catch(() => {
          this.isLoadingTicket = false;

          this.resetBill();
          this.addNotification({
            message: `Failed to ${ticketStatus ? "open" : "close"} ticket Id ${
              this.newTicket.id
            }`,
            type: "error",
          });
          this.$emit("onStatusUpdate", {
            ticket: this.newTicket,
            success: false,
          });
        });
    },
    setBillable(event: string) {
      this.getBillingTypes.map((element: BillingTypeItem) => {
        if (event == element.billing_type) {
          this.billable = element.billable as unknown as boolean;
        }
      });
    },
    fetchNewTicket() {
      this.fetchTickets({
        custs: [this.newTicket?.cust] || [],
        ids: this.newTicket.id,
        status: "",
        addTicket: false,
        assignee: this.newTicket.assigned_to,
        correls: "cust_name",
      });
    },
    resetBill() {
      this.newBill = {} as Bill;
      this.newBill.user = this.getUser.user_id;
      this.newBill.date = "" as unknown as string;
    },
    initFields() {
      this.newTicket = {} as Ticket;
      this.resetBill();

      this.newTicket.assigned_to = this.getUser.user_id;
      this.newTicket.date = new Date() as unknown as string;
      if (this.customer) {
        this.newTicket.cust = this.customer;
      }

      this.billable = false;
      this.newTicketCust = "";
      this.submitted = false;
      this.part = null;
    },
    postClosedTicket() {
      return new Promise((resolve, reject) => {
        // CLOSE TICKET
        this.newTicket.closed = "";
        const closedNewTicket = JSON.parse(JSON.stringify(this.newTicket));
        closedNewTicket.closed = "Y";
        this.ticketService
          .updateTicket({
            newTicket: closedNewTicket,
            oldTicket: this.newTicket,
            ticket_id: this.newTicket.id,
          })
          .then(() => {
            this.addNotification({
              message: `Successfully updated and closed ticket Id ${this.newTicket.id}`,
              type: "success",
            });
            this.isLoadingTicket = false;
            this.submitted = false;
            this.newTicket.closed = "Y";
            this.updateTicketRecord(
              JSON.parse(JSON.stringify(closedNewTicket))
            );
            this.selectedTicket = JSON.parse(JSON.stringify(closedNewTicket));
            resolve(true);
            this.$emit("onStatusUpdate", { success: true });
          })
          .catch((error) => {
            this.addNotification({
              message: `Could not close ticket Id ${this.newTicket.id}.`,
              type: "error",
            });
            this.isLoadingTicket = false;
            reject(false);
            this.$emit("onStatusUpdate", { success: false });
          });
      });
    },
  },
  watch: {
    show(newShow) {
      this.isHidden = newShow;
      this.initFields();
    },
  },
});
