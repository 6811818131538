
import { defineComponent } from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import Datatable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";

import AddAttachmentDialog from "@/components/Attachments/AddAttachmentDialog.vue";
import EmailAttachmentDialog from "@/components/Attachments/EmailAttachmentDialog.vue";
import ConfirmChangesDialog from "@/components/ConfirmChangesDialog.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

import Attachment from "@/types/attachment";

import Utils from "@/utility/utils";

export default defineComponent({
  name: "Attachments Section",
  components: {
    Datatable,
    Column,
    Button,
    AddAttachmentDialog,
    EmailAttachmentDialog,
    ConfirmChangesDialog,
    LoadingSpinner
  },
  props: {
    show: Boolean,
    showMail: {
      type: Boolean,
      default: false,
    },
    customerArrayIndex: Number,
    isLoadingAttachment: Boolean,
    isLogHidden: {
      type: Boolean,
      default: true,
    },
    record: Object,
    hostFile: String,
    hostId: String,
    showTotalAttachments: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    "hide",
    "onUploadAttachment",
    "onSaved",
    "onFetchRecords",
    "onDownload",
    "onDeleted",
    "onPageClicked",
  ],
  created() {
    if ((this.hostFile === "SALEOPP" || this.hostFile === "TICKET" || this.hostFile === 'SOQUOTE' || this.hostFile === "AP" || this.hostFile === "GLTRANS") && this.attachmentRecords.length === 0) {
      this.fetchAttachmentRecords();
    }

    if (this.hostFile === "CUST") {
      if ((this.record as any).attachments_items != null && this.getAttachments(this.customerArrayIndex) == null || this.getAttachments(this.customerArrayIndex).length === 0) {
        this.fetchAttachmentRecords(false);
      } else {
        this.attachmentRecords = this.getAttachments(this.customerArrayIndex || 0);
        this.totalAttachments = this.attachmentRecords?.length || 0;
      }
      this.first = this.getFirstRow(this.customerArrayIndex);
    }
    this.isAttachmentLogHidden = this.isLogHidden
  },
  updated(){
    if ((this.hostFile === "SALEOPP" || this.hostFile === "SOQUOTE") && this.attachmentRecords.length === 0) {
      this.fetchAttachmentRecords();
    }
  },
  data() {
    return {
      isHidden: false,
      isAttachmentLogHidden: true,
      loadingAttachments: false,
      showAddTicketAttachmentDialog: false,
      showEmailAttachmentDialog: false,
      currentAttachmentId: "",
      fileName: "",
      attachmentRecords: [] as Array<Attachment>,
      loadingAttachmentIds: [] as Array<string>,
      deletingAttachmentIds: [] as Array<string>,
      recordObject: {},
      totalAttachments: 0,
      isUploading: false,
      rows: 5,
      first: 0,
      page: 1,
      currentId: -1,
      mockedAttachments: [
        {"id":"11672091815","file_name":"fine.jpg","created_by":"RICARDOC","date_created":"08/24/2023","time_created":"15:19","mime_type":"image/jpeg"},{"id":"37792091190","file_name":"image.jpg","created_by":"SHOSHANAS","date_created":"07/11/2023","time_created":"09:43","mime_type":"image/jpeg"},
        {"id":"1235807932","file_name":"Rover SaaS Agreement - Sensapure Signed.txt","created_by":"WRIGHTM","date_created":"07/05/2023","time_created":"12:02"},{"id":"24109582091375","file_name":"Git.txt","created_by":"SHOSHANAS","date_created":"06/05/2023","time_created":"09:22","mime_type":"text/plain"},{"id":"24106862091360","file_name":"4-11.txt","created_by":"SHOSHANAS","date_created":"06/05/2023","time_created":"09:18","mime_type":"text/plain"},{"id":"20905762091623","file_name":"scene.jpg","created_by":"SHOSHANAS","date_created":"06/01/2023","time_created":"11:15","mime_type":"image/jpeg"},{"id":"46646932091226","file_name":"desktop-version.json","created_by":"CHRISTIANO","date_created":"04/21/2023","time_created":"11:23","mime_type":"application/json"},{"id":"33714212091935","file_name":"Screenshot from 2023-04-05 22-31-25.png","created_by":"AKASHS","date_created":"04/12/2023","time_created":"03:41","mime_type":"image/png"},{"id":"16300112091553","file_name":"Rover-Logo-2-High-1.png","created_by":"SHOSHANAS","date_created":"03/28/2023","time_created":"16:56","mime_type":"image/png"}] as Array<Attachment>,
    };
  },
  computed: {
    ...mapState(["control"]),
    ...mapGetters({
      getAttachments: "attachments/getCustomerAttachments",
      getRangeEnd: "attachments/getCustomerAttachmentRangeEnd",
      getFolderNames: "control/getFolderNames",
      getClient: "session/getClient",
      getUser: "session/getUser",
      getFirstRow: "attachments/getCustomerAttachmentFirstRow",
    }),
    isAttachmentLogVisible(){
      if (this.hostFile === "CUST") return false;
      return this.isAttachmentLogHidden;
    }
  },
  methods: {
    ...mapActions({
      fetchControl: "control/fetchControl",
      postAttachment: "attachments/postAttachment",
      downloadAttachment: "attachments/downloadAttachment",
      deleteAttachment: "attachments/deleteAttachment",
      fetchAttachments: "attachments/fetchAttachments",
      addNotification: "notification/add",
      updatePage: "attachments/updatePage",
      setNextRow: "attachments/updateCustomerRowIndex"
    }),
    viewAttachmentsLog() {
      this.isAttachmentLogHidden = !this.isAttachmentLogHidden;
    },
    showEmailModal({ id, file_name }: any) {
      this.showEmailAttachmentDialog = true;
      this.currentAttachmentId = id;
      this.fileName = file_name;
    },
    getDate(attachment: Attachment) {
      if(attachment && attachment.date_created) {
        return Utils.formatDate(attachment.date_created);
      } else {
        return "";
      }
    },
    getDescription(id: string) {
      if(!this.record) return "";
      switch (this.hostFile) {
        case "SALEOPP":
          return (
            (this.record as any).attachments_items?.find(
              (attachment: any) => attachment.attachments === id
            )?.attach_desc || ""
          );
        default:
          return (
            (this.record as any).attachments_items?.find(
              (attachment: any) => attachment.attachments === id
            )?.attachment_desc || ""
          );
      }
    },
    downloadIcon(ticketId: string) {
      const downloading = this.loadingAttachmentIds.includes(ticketId) || false;

      if (!downloading) return "pi pi-download";
      return "pi pi-spin pi-spinner";
    },
    download({ id }: any) {
      this.loadingAttachmentIds.push(id);

      const record: Attachment = this.attachmentRecords?.find(
        (attachment) => attachment.id === id
      ) as Attachment;

      if(record == null) {
        this.addNotification({
          message: `Error downloading attachment`,
          type: "error",
        });
        return;
      }

      let mime_type = record?.mime_type;

      if (mime_type != null) mime_type = "text/plain";

      this.downloadAttachment({
        id,
        mime_type,
      })
      .then(() => {
        this.$emit("onDownload", { success: true, attachmentId: id });
      })
      .catch((error) => {
        this.$emit("onDownload", { success: false, error });
      })
      .finally(() => {
        this.removeDownloadingState(id);
      });
    },
    deleteIcon(ticketId: string) {
      const deletingRecord =
        this.deletingAttachmentIds.includes(ticketId) || false;

      if (!deletingRecord) return "pi pi-trash";
      return "pi pi-spin pi-spinner";
    },
    remove(id: string) {
      this.deletingAttachmentIds.push(id);

      this.deleteAttachment({
        id,
        host_file: this.hostFile,
        host_id: this.hostId ? this.hostId : this.hostFile === "CUST" ? (this.record as any).cust_id : (this.record as any).id,
        index: this.customerArrayIndex
      })
        .then(() => {
          if (this.hostFile === "CUST") {
            this.attachmentRecords = this.getAttachments(this.customerArrayIndex);
          }
          this.removeDeleteLoadingState(id);
          this.$emit("onDeleted", { success: true, attachmentId: id});
        })
        .catch(({ error }) => {
          this.removeDeleteLoadingState(id);
          this.$emit("onDeleted", { success: false, error });
        });
    },
    removeDeleteLoadingState(id: string) {
      const attachmentId = this.deletingAttachmentIds.indexOf(id);
      if (attachmentId > -1) {
        this.deletingAttachmentIds.splice(attachmentId, 1);
      }
    },
    removeDownloadingState(id: string) {
      const attachmentId = this.loadingAttachmentIds.indexOf(id);
      if (attachmentId > -1) {
        this.loadingAttachmentIds.splice(attachmentId, 1);
      }
    },
    save(data: any) {
      const { desc, doc, doc_name, folder, mime_type } = data;

      this.isUploading = true;
      const recordId = this.hostId ? this.hostId : this.hostFile === "CUST" ? (this.record as any).cust_id : (this.record as any).id;

      this.postAttachment({
        doc_name,
        doc,
        mime_type,
        folder,
        host_file: this.hostFile,
        host_id: recordId,
        user_id: this.getUser.user_id,
        desc,
        index: this.customerArrayIndex
      })
        .then(({ attachment_id }) => {
          this.isUploading = false;
          this.showAddTicketAttachmentDialog = false;
          if (this.hostFile === "CUST") {
            this.attachmentRecords = this.getAttachments(this.customerArrayIndex);
          }

          this.$emit("onSaved", {
            success: true,
            attachmentId: attachment_id,
            recordId: recordId,
          });
          this.first = 0;
        })
        .catch((error) => {
          this.isUploading = false;
          this.$emit("onSaved", { success: false, error });
          this.addNotification({
            message: `Error saving attachment`,
            type: "error",
          });
        });
    },
    async confirmDelete(id: string) {
      const res: boolean = await (
        this.$refs["confirmChanges"] as any
      ).open();
      
      if (res) {
        this.remove(id);
      }
    },
    async fetchAttachmentRecords(isAddingAttachments?: boolean) {
      const attachmentsRecords =
        (this.record as any)?.attachments_items != null
          ? [...(this.record as any)?.attachments_items]
          : [] as Array<Attachment>;

      if (attachmentsRecords.length > 0) {
        this.loadingAttachments = true;
        this.fetchAttachments({
          record: this.record as any,
          index: this.customerArrayIndex ?? undefined,
          isAddingAttachments,
        })
          .then(({ attachments }) => {
            this.loadingAttachments = false;
            this.attachmentRecords = attachments;
            this.totalAttachments = this.attachmentRecords?.length || 0;
            this.$emit("onFetchRecords", {
              success: true,
              totalRecords: this.totalAttachments,
            });
          })
          .catch((error) => {
            this.loadingAttachments = false;
            this.$emit("onFetchRecords", { success: false, error });
          });
        if (isAddingAttachments != null && !isAddingAttachments) {
          this.page = 1;
          this.first = 0;
        }
      } else {
        // TODO: Handle the attachment recors for AP and GL records
        if (this.hostFile === "AP") {
          this.attachmentRecords = this.mockedAttachments;
          this.totalAttachments = this.attachmentRecords?.length || 0;
          return;
        }
        if (this.hostFile === "CUST") {
          this.attachmentRecords = this.getAttachments(this.customerArrayIndex || 0)
        } 
        this.totalAttachments = this.attachmentRecords?.length || 0;
      }
    },
    handlePage(event: any) {
      if (this.hostFile != null && this.hostFile === "CUST"){
        this.$emit("onPageClicked", { pageNumber: event.page, firstPageIndex: event.first, pageCount: event.pageCount });
        // this.updatePage({ pageNumber: event.page, index: this.index });
        // this.setNextRow({ row: event.first, index: this.index });
        if (
          (event.page == event.pageCount ||
            event.page == event.pageCount - 1 ||
            event.page == event.pageCount - 2) &&
          (this.getAttachments(this.customerArrayIndex)?.length || 0) == this.getRangeEnd(this.customerArrayIndex) - 100
        ) {
          this.fetchAttachmentRecords(true);
        }
      }
    },
  },
  watch: {
    show(newShow) {
      this.isHidden = newShow;
    },
    record(newRecord) {
      this.recordObject = newRecord;
      if (this.hostFile === "CUST") {
        if (this.getAttachments(this.customerArrayIndex) == null || this.getAttachments(this.customerArrayIndex).length === 0) {
          this.fetchAttachmentRecords(false);
        } else {
          this.attachmentRecords = this.getAttachments(this.customerArrayIndex);
        }
      }
      
      if (this.hostFile === "SALEOPP" && newRecord) {
        this.fetchAttachmentRecords();
      }

      if (this.hostFile === "SOQUOTE" && newRecord) {
        this.fetchAttachmentRecords();
      }
    },
  },
});
